<template>
  <div class="test-page">
    <BackToPage v-if="isProd"></BackToPage>

    <div v-else class="test-page">
      <ResizableBlock mode="horizontal">
        <div slot="start" class="item start">
          <a-button @click="showModal">打开视频弹窗</a-button>
        </div>

        <div slot="end" class="item end">
          <a-button @click="exportExcel">导出表格</a-button>
        </div>
      </ResizableBlock>
    </div>

    <AudioResult v-if="modalShown" :data="{content: ''}" @close="close"></AudioResult>
  </div>
</template>

<script>
import { isProd } from '@/Config';

import BackToPage from '@components/BackToPage';
import ResizableBlock from '@components/ResizableBlock';
import AudioResult from '@views/statistics/components/AudioResult';

import { exportExcel } from '../utils/ExportExcel';

export default {
  name: 'TestPage',
  components: { BackToPage, ResizableBlock, AudioResult },
  data() {
    return {
      isProd: isProd,
      modalShown: false,
    };
  },

  methods: {
    exportExcel() {
      exportExcel({
        aoaList: [[
          ['姓名', '性别', '年龄', '注册时间'],
          ['张三', '男', 18, new Date()],
          ['李四', '女', 22, new Date()],
        ], [
          ['姓名', '性别', '年龄', '注册时间'],
          ['张三', '男', 18, new Date()],
          ['李四', '女', 22, new Date()],
        ]],
        cell: [{
          type: '!merges',
          value: [{ s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }],
        }],
        sheetNames: ['表格1', '列表'],
      });
    },

    showModal() {
      this.modalShown = true;
    },
    close() {
      this.modalShown = false;
    },
  },
};
</script>

<style scoped lang="scss">
.test-page {
  width: 100%;
  height: 100%;

  .item {
    width: 100%;
    height: 100%;
  }

  .start {
    background-color: green;
  }

  .end {
    background-color: blue;
  }
}
</style>
