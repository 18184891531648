// 贴片配置
const Index = () => import(/* webpackChunkName: "Advertising" */ '@views/advertising/advertising/sticker/Index.vue');
const List = () => import(/* webpackChunkName: "Advertising" */ '@views/advertising/advertising/sticker/List.vue');
const Detail = () => import(/* webpackChunkName: "Advertising" */ '@views/advertising/advertising/sticker/Detail.vue');

const routes = [
  {
    path: 'sticker',
    component: Index,
    meta: { title: '贴片配置' },
    children: [
      { path: '', redirect: 'list' },
      { path: 'list', component: List, meta: { title: '贴片列表' } },
      { path: 'create', component: Detail, meta: { title: '添加贴片' } },
      { path: 'edit', component: Detail, meta: { title: '编辑贴片' } },
      { path: 'detail', component: Detail, meta: { title: '贴片详情' } },
    ],
  },
];

export default routes;
