import { stringify } from 'qs';
import { baseUrl } from '@/Config';
import store from '@/store/index';
  //timeout: 300000, //去除时间限制
import request from '@/utils/RequestNoLimits';
import { downloadFileExtra } from '@/utils/JsDownload';

// 经销商-完训统计
export function getParticipationBigAreaFinish(params) {
  return request({
    url: '/participation/admin/area/noexamFixed',
    // url: '/participation/admin/area/noexam',
    method: 'get',
    params,
  });
}

// 经销商-完训统计 - 下载
export function getParticipationBigAreaFinishDownload(params) {
  let url = '/participation/admin/area/noexamFixed';
  // let url = '/participation/admin/area/noexam';
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}

// 经销商-考试统计
export function getParticipationBigAreaExam(params) {
  return request({
    url: '/participation/admin/area/examFixed',
    // url: '/participation/admin/area/exam',
    method: 'get',
    params,
  });
}

// 经销商-考试统计 - 下载
export function getParticipationBigAreaExamDownload(params) {
  let url = '/participation/admin/area/examFixed';
  // let url = '/participation/admin/area/exam';
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}

// 经销商-参训统计
export function getParticipationBigAreaParticipate(params) {
  return request({
    url: '/participation/admin/area/finishFixed',
    // url: '/participation/admin/area/finish',
    method: 'get',
    params,
  });
}

// 经销商-参训统计 - 下载
export function getParticipationBigAreaParticipateDownload(params) {
  let url = '/participation/admin/area/finishFixed';
  // let url = '/participation/admin/area/finish';
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}
