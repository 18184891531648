import 'babel-polyfill';
import 'normalize.css/normalize.css'; // a modern alternative to CSS resets

import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';

import '@/plugins';

import { isProd } from '@/Config';

Vue.config.productionTip = !isProd;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
