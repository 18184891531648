<template>
  <div ref="appHeaderRef" class="app-header">
    <div ref="logoRef" class="logo">
      <div class="logo-inner" @click="goHome()">
        <img :src="logoPath" alt="" class="logo-img">
        <span class="logo-txt fix-txt-vertical">奔腾苑管理后台</span>
      </div>
    </div>

    <div ref="headerMainRef" class="header-main">
      <slot name="menu"></slot>
    </div>

    <a-dropdown ref="dropRef" class="drop-button">
      <div>
        <a-avatar :size="32" :src="userAvatar" class="drop-button-avatar"/>
        <span class="drop-button-txt">{{ userName }}</span>
        <a-icon type="caret-down" class="drop-button-img"/>
      </div>

      <a-menu slot="overlay" theme="dark">
        <a-menu-item @click="logOut()">注销登录</a-menu-item>
      </a-menu>
    </a-dropdown>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import { label } from '@/Config';
import { removeToken } from '@/utils/Token';
import Common from '@/utils/Common';

export default {
  name: 'Header',
  computed: {
    ...mapState({
      homeUrl: state => state.User.homeUrl,
      logOutUrl: state => state.User.logOutUrl,
      userName: state => state.User.userName,
      trueName: state => state.User.trueName,
      avatar: state => state.User.headImg,
    }),
    logoPath() {
      const path = `${label}/logo.png`;
      return require('@/assets/' + path);
    },
    userAvatar() {
      return this.avatar ? this.avatar : require('@assets/img/avatar.png');
    },
  },
  watch: {
    userName() {
      this.$nextTick(() => this.setHeaderMainSize());
    },
  },
  data() {
    return {
      userDropShown: false,
    };
  },
  mounted() {
    document.addEventListener('click', this.hideUserDrop, false);
    window.addEventListener('resize', this.setHeaderMainSize, false);
    this.setHeaderMainSize();
  },
  destroyed() {
    document.removeEventListener('click', this.hideUserDrop);
  },
  methods: {
    setHeaderMainSize() {
      const appHeaderRef = this.$refs.appHeaderRef;
      const logoRef = this.$refs.logoRef;
      const dropRef = this.$refs.dropRef?.$el;

      const headerMainRef = this.$refs.headerMainRef;

      const headerWidth = parseFloat(Common.getStyle(appHeaderRef, 'width')) || 0;
      const logoWidth = parseFloat(Common.getStyle(logoRef, 'width')) || 0;
      const dropWidth = parseFloat(Common.getStyle(dropRef, 'width')) || 0;

      headerMainRef.style.width = (headerWidth - logoWidth - dropWidth - 32) + 'px';
    },

    goHome() {
      const local = window.location.href;
      window.location.href = this.homeUrl || local;
    },
    logOut() {
      removeToken();
      this.$store.commit('User/updateToken', '');
      const local = window.location.href;
      window.location.href = this.logOutUrl || local;
    },

  },
};
</script>

<style scoped lang="scss">
.app-header {
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: $headerHeight;
  background-color: $basic01;

  span.fix-txt-vertical {
    margin-top: -2px;
  }

  .header-main {
    flex: 1 1 0;
  }

  .logo {
    flex: 0 0 211px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 211px;
    height: $headerHeight;
    padding: 0 23px 0 16px;
    border-right: 1px solid $basic02;
  }

  .logo-inner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 34px;
    line-height: normal;
    cursor: pointer;
  }

  .logo-img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .logo-txt {
    font-size: 14px;
    color: $light01;
  }

  .drop-button {
    flex: 0 0 auto;
    position: relative;
    margin-left: 16px;
    margin-right: 16px;
    height: $headerHeight;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .drop-button-txt {
    font-size: 14px;
    color: $light02;
  }

  .drop-button-avatar {
    margin-right: 5px;
  }

  .drop-button-img {
    color: $light02;
  }
}
</style>

<style lang="scss">
.ant-dropdown {
  .ant-dropdown-menu-item.ant-dropdown-menu-item-active, .ant-menu-item.ant-menu-item-selected {
    background-color: $basic03 !important;
  }
}
</style>
