<script>
import SelectWithPager from '@components/select/SelectWithPager.vue'

import { exportExcel } from '@/utils/ExportExcel'

import { uploadFileToPublic } from '@api/Common'
import {
  getCoursePushTypeDetail,
  addCourseRoleUsers,
  addTaskRoleUsers,
  addCsvRoleUsers
} from './api'


const userRoleType = [
  // { id: 0, name: '全部用户' },
  // { id: 1, name: '认证用户' },
  // { id: 2, name: '未认证用户' },
  { id: 3, name: '关联课程' },
  { id: 4, name: '关联任务' },
  { id: 5, name: '名单用户' }
]


export default {
  components: { SelectWithPager },
  data() {
    return {
      loading: false,
      userRoleTypeList: userRoleType,

      courseList: [],
      taskList: [],

      formState: {
        user_role_type: userRoleType[0].id,
        course: [{ old_course_id: undefined, is_finish: '0', title: '' }],
        task: [{ old_task_id: undefined, is_finish: '0', title: '' }],
        fileList: []
      },

      form: this.$form.createForm(this, { name: 'course_push_type' }),
      decorator: {
        userRoleType: {
          rules: [{ required: true, message: '请选择推送类型' }]
        }
      }
    }
  },
  created() {
  },
  mounted() {
    this.getCoursePushTypeDetail()
  },
  methods: {
    async getCoursePushTypeDetail() {
      this.loading = true

      const params = { course_id: this.$route.query.id }
      const data = await getCoursePushTypeDetail(params).catch(() => '')
      if(!data || data.error_code) {
        this.loading = false
        return
      }

      const roleType = +data.data?.[0]?.user_role_type || userRoleType[0].id

      const formState = { user_role_type: roleType, course: [], task: [], fileList: [] }

      data.data.forEach((item) => {
        if(item.user_role_type === 3) {
          const list = JSON.parse(item.courses_data || '{}')?.courses || []
          formState.course.push(...list)
        } else if(item.user_role_type === 4) {
          const list = JSON.parse(item.courses_data || '{}')?.tasks || []
          formState.task.push(...list)
        } else if(item.user_role_type === 5) {
          const url = JSON.parse(item.courses_data || '{}')?.file_url || ''
          const name = JSON.parse(item.courses_data || '{}')?.file_name || url.split('/').reverse()[0] || ''
          formState.fileList.push({
            uid: -1,
            status: 'done',
            url,
            name
          })
        }
      })

      this.loading = false

      if(!formState.course.length) {
        formState.course.push({ old_course_id: undefined, is_finish: '0', title: '' })
      }
      if(!formState.task.length) {
        formState.task.push({ old_task_id: undefined, is_finish: '0', title: '' })
      }

      this.form.setFieldsValue({ 'user_role_type': roleType })
      this.formState = formState
    },

    onUpdateValue() {
      this.$nextTick(() => {
        this.formState.user_role_type = this.form.getFieldValue('user_role_type')
      })
    },

    onSelectChange(ids, values, item) {
      item.title = values[0].title
    },

    addCourse(index) {
      if(this.loading) {
        return
      }
      this.formState.course.splice(index + 1, 0, { old_course_id: undefined, is_finish: '0' })
    },
    removeCourse(index) {
      if(this.loading) {
        return
      }
      this.formState.course.splice(index, 1)
    },

    addTask(index) {
      if(this.loading) {
        return
      }
      this.formState.task.splice(index + 1, 0, { old_task_id: undefined, is_finish: '0' })
    },
    removeTask(index) {
      if(this.loading) {
        return
      }
      this.formState.task.splice(index, 1)
    },

    async onSubmit() {
      if(this.formState.user_role_type === 3) {
        return await this.onSaveCourseRoleUsers()
      }
      if(this.formState.user_role_type === 4) {
        return await this.onSaveTaskRoleUsers()
      }
      if(this.formState.user_role_type === 5) {
        return await this.onSaveCsvRoleUsers()
      }
      return 0
    },

    async onSaveCourseRoleUsers() {
      if(this.formState.course.some((i) => !i.old_course_id)) {
        this.$message.warn('请选择课程')
        return 0
      }

      if(this.loading) {
        return 0
      }
      this.loading = true

      const params = {
        course_id: this.$route.query.id,
        courses_data: JSON.stringify({ 'courses': this.formState.course || [] })
      }
      const data = await addCourseRoleUsers(params).catch(() => '')

      this.loading = false

      if(!data || data.error_code) {
        this.$message.error('保存失败')
        return 0
      }

      this.$message.success('保存成功')
      return 1
    },

    async onSaveTaskRoleUsers() {
      if(this.formState.task.some((i) => !i.old_task_id)) {
        this.$message.warn('请选择任务')
        return 0
      }

      if(this.loading) {
        return 0
      }
      this.loading = true

      const params = {
        course_id: this.$route.query.id,
        tasks_data: JSON.stringify({ 'tasks': this.formState.task || [] })
      }
      const data = await addTaskRoleUsers(params).catch(() => '')

      this.loading = false

      if(!data || data.error_code) {
        this.$message.error('保存失败')
        return 0
      }

      this.$message.success('保存成功')
      return 1
    },

    async onSaveCsvRoleUsers() {
      if(!this.formState.fileList?.[0]) {
        this.$message.warn('请选择文件')
        return 0
      }

      if(this.loading) {
        return 0
      }
      this.loading = true

      if(!this.formState.fileList[0].url) {
        const result = await this.onUploadFile(this.formState.fileList[0].originFileObj)
        this.formState.fileList[0].url = result.data.url

        if(!result || result.error_code) {
          this.$message.error('文件上传失败')
          return 0
        }
      }

      const params = {
        course_id: this.$route.query.id,
        file_url: this.formState.fileList[0].url || '',
        file_name: this.formState.fileList[0].originFileObj?.name || this.formState.fileList[0].name || ''
      }
      const data = await addCsvRoleUsers(params).catch(() => '')

      this.loading = false

      if(!data || data.error_code) {
        this.$message.error('保存失败')
        return 0
      }

      this.$message.success('保存成功')
      return 1
    },

    beforeFileUpload(file) {
      return /\.(xlsx|xls|csv)$/g.test(file.name)
    },

    onCustomRequest({ onSuccess } = {}) {
      setTimeout(() => {
        onSuccess({}, {})
      }, 0)
    },

    removeFile() {
      this.formState.fileList = []
    },

    onFileChange({ file } = {}) {
      if(file.status === 'removed') {
        this.formState.fileList = []
      } else {
        this.formState.fileList = [file]
      }
    },

    async onUploadFile(file) {
      const formData = new FormData()
      formData.append('file', file)
      return await uploadFileToPublic(formData).catch(() => '')
    },

    onDownloadTemplate() {
      exportExcel({
        aoaList: [[['身份证号'], ['232331198505090649']]],
        cell: [{
          type: '!cols',
          value: [{ wch: 26 }]
        }],
        sheetNames: ['导入推送名单(demo)'],
        fileName: '导入推送名单（示例）.xlsx'
      })
    },

    async onNext() {
      if(!this.$route.query.id) {
        return
      }
      const result = await this.onSubmit()
      if(result) {
        top.location.href = '/admin/lesson/add_two.html?id=' + this.$route.query.id || ''
      }
    },
    onBack() {
      top.location.href = '/admin/lesson/index'
    }
  }
}
</script>

<template>
  <a-form :form="form"
          label-align="right"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 20 }"
          class="form">
    <a-form-item label="推送类型">
      <a-radio-group v-decorator="['user_role_type', decorator.userRoleType]"
                     :disabled="loading"
                     @change="onUpdateValue">
        <a-radio v-for="(item) in userRoleTypeList" :key="item.id" :value="item.id">
          {{ item.name }}
        </a-radio>
      </a-radio-group>
    </a-form-item>

    <a-form-item v-show="formState.user_role_type === 3" label="选择课程">
      <div v-for="(item, index) in formState.course" :key="index" class="form-item-row">
        <SelectWithPager v-model="item.old_course_id"
                         :disabled="loading"
                         :use-local-pager="false"
                         mode="default"
                         name="old_course_id"
                         :options="{id: 'id', name: 'title'}"
                         :permanently-display-options="formState.course.map(i => Object.assign({id: +i.old_course_id, title: i.title})).filter(i => !!i.id)"
                         api="/course/admin/courseList"
                         filter-field="keywords"
                         placeholder="请选择课程"
                         class="select"
                         @change="(ids, values) => onSelectChange(ids, values, item)" />

        <a-radio-group v-model="item.is_finish" :disabled="loading">
          <a-radio value="0">未完成用户</a-radio>
          <a-radio value="1">已完成用户</a-radio>
        </a-radio-group>

        <div>
          <a-icon type="plus-circle" class="icon" @click="addCourse(index)" />
          <template v-if="formState.course.length > 1">
            <a-icon type="minus-circle" class="icon" @click="removeCourse(index)" />
          </template>
        </div>
      </div>
    </a-form-item>

    <a-form-item v-show="formState.user_role_type === 4" label="选择任务">
      <div v-for="(item, index) in formState.task" :key="index" class="form-item-row">
        <SelectWithPager v-model="item.old_task_id"
                         :disabled="loading"
                         :use-local-pager="false"
                         mode="default"
                         name="old_task_id"
                         :options="{id: 'id', name: 'title'}"
                         :permanently-display-options="formState.task.map(i => Object.assign({id: +i.old_task_id, title: i.title})).filter(i => !!i.id)"
                         api="/course/admin/taskList"
                         filter-field="keywords"
                         placeholder="请选择任务"
                         class="select"
                         @change="(ids, values) => onSelectChange(ids, values, item)" />

        <a-radio-group v-model="item.is_finish" :disabled="loading">
          <a-radio value="0">未完成用户</a-radio>
          <a-radio value="1">已完成用户</a-radio>
        </a-radio-group>

        <div>
          <a-icon type="plus-circle" class="icon" @click="addTask(index)" />
          <template v-if="formState.task.length > 1">
            <a-icon type="minus-circle" class="icon" @click="removeTask(index)" />
          </template>
        </div>
      </div>
    </a-form-item>

    <a-form-item v-show="formState.user_role_type === 5" label="添加推送名单">
      <div>
        <a-upload name="file"
                  :disabled="loading"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  :file-list="formState.fileList"
                  :multiple="false"
                  :remove="removeFile"
                  :before-upload="beforeFileUpload"
                  :custom-request="onCustomRequest"
                  @change="onFileChange">
          <a-button :disabled="loading">上传</a-button>
        </a-upload>
      </div>
      <div class="upload-people-prompt">已添加推送名单，再次上传将会覆盖原有名单！</div>
      <div class="upload-people-template-button">
        <a-button type="link" @click="onDownloadTemplate">下载模板</a-button>
      </div>
    </a-form-item>


    <a-form-item label=" " :colon="false">
      <a-button type="primary" :loading="loading" class="next-button" @click="onNext">保存并下一步</a-button>
      <a-button type="primary" :loading="loading" class="save-button" @click="onSubmit">保存本页</a-button>
      <a-button type="primary" :loading="loading" class="back-button" @click="onBack">返回列表</a-button>
    </a-form-item>
  </a-form>
</template>

<style scoped lang="scss">
.form {
  padding: 14px 24px 200px;
}

.form-item-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;

  .select {
    flex: none;
    width: 320px;
    margin-right: 10px;
  }

  .icon {
    margin: 10px;
    font-size: 18px;
  }
}

::v-deep .ant-form-item {
  padding: 20px 0 12px;
  border-bottom: 1px solid #DDDDDD;
}

.upload-people-prompt {
  line-height: 1.2em;
  margin: 15px 0;
  font-size: 12px;
  color: #1890FF;
}

.upload-people-template-button {
  margin-left: -15px;
}
</style>
