const RouterView = () => import(/* webpackChunkName: "Limits" */  '@views/routerView/Index.vue');

const Index = () => import(/* webpackChunkName: "Limits" */  '@views/limits/Index.vue');
const List = () => import(/* webpackChunkName: "Limits" */  '@views/limits/List.vue');

const routes = [
  {
    path: 'limits',
    component: Index,
    meta: { title: '权限管理' },
    children: [
      { path: '', redirect: 'list' },
      {
        path: 'list',
        component: RouterView,
        meta: { title: '子权限管理' },
        children: [
          { path: '', component: List },
        ],
      },
    ],
  },
];

export default routes;
