<template>
  <div class="app-back-to-page">
    <router-link :to="url">{{ text }}</router-link>
  </div>
</template>

<script>
export default {
  name: 'BackToPage',
  props: {
    url: {type: String, default: '/index'},
    text: {type: String, default: '返回首页'},
  },
}
</script>

<style scoped lang="scss">
.app-back-to-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 60px;
  margin: 0 auto;
}
</style>
