import Frame from '@/frame/Index.vue';

import PostDeletedLog from '@/views/user/post/DeletedRecord.vue';

import PushType from '@/views/course/detail/PushType.vue'

const route = {
  path: '/frame',
  component: Frame,
  children: [
    {
      path: 'post-del-log',
      component: PostDeletedLog,
    },
    {
      path: 'course-push-type',
      component: PushType,
    }
  ],
};

export default route;
