import Offline from './Offline';
import Schedule from './Schedule';


const routes = [
  ...Offline,
  ...Schedule,
];

export default routes;
