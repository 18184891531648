<template>
  <div class="app-main">
    <div v-if="list && list.length" class="flex flex-start bread-crumb">
      <a-breadcrumb separator="/">
        <a-breadcrumb-item v-for="(item, idx) of list" :key="idx">{{ item.meta.title || '' }}</a-breadcrumb-item>
      </a-breadcrumb>
    </div>

    <router-view class="app-main-router-view"/>
  </div>
</template>

<script>
export default {
  name: 'Main',
  computed: {
    list() {
      const { matched } = this.$route || {};
      return matched.filter((i) => i?.meta?.title).map((i) => ({ path: i.path, meta: i.meta }));
    },
  },
};
</script>

<style scoped lang="scss">
.app-main {
  border-radius: 2px;
  background-color: $light01;

  .bread-crumb {
    flex: none;
    height: 50px;
    padding: 10px 20px;
    white-space: nowrap;
  }
}
</style>
