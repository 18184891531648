import request from '@/utils/Request'

// 模糊查询任务接口
export function getCoursePushTypeDetail(params) {
  return request({
    url: '/course/admin/getCourseTypeInfo',
    method: 'get',
    params
  })
}

// 关联课程推送人群
export function addCourseRoleUsers(data) {
  return request({
    url: '/course/admin/addCourseRoleUsers',
    method: 'post',
    data
  })
}

// 关联任务推送人群
export function addTaskRoleUsers(data) {
  return request({
    url: '/course/admin/addTaskRoleUsers',
    method: 'post',
    data
  })
}

// 关联名单推送人群
export function addCsvRoleUsers(data) {
  return request({
    url: '/course/admin/addCsvRoleUsers',
    method: 'post',
    data
  })
}
