const RouterView = () => import(/* webpackChunkName: "Certificate" */  '@views/routerView/Index.vue');

const OverviewIndex = () => import(/* webpackChunkName: "Certificate" */  '@views/certificate/overview/Index.vue');
const OverviewOffline = () => import(/* webpackChunkName: "Certificate" */  '@views/certificate/overview/Offline.vue');
const OverviewOnline = () => import(/* webpackChunkName: "Certificate" */  '@views/certificate/overview/Online.vue');

const CodeIndex = () => import(/* webpackChunkName: "Certificate" */  '@views/certificate/code/Index.vue');
const CodeManagement = () => import(/* webpackChunkName: "Certificate" */  '@views/certificate/code/Management.vue');

const TemplateIndex = () => import(/* webpackChunkName: "Certificate" */  '@views/certificate/template/Index.vue');
const TemplateList = () => import(/* webpackChunkName: "Certificate" */  '@views/certificate/template/List.vue');
const TemplateDetail = () => import(/* webpackChunkName: "Certificate" */  '@views/certificate/template/Detail.vue');

const ImportIndex = () => import(/* webpackChunkName: "Certificate" */  '@views/certificate/import/Index.vue');
const ImportList = () => import(/* webpackChunkName: "Certificate" */  '@views/certificate/import/List.vue');

const routes = [
  {
    path: 'overview',
    component: OverviewIndex,
    meta: { title: '证书查询' },
    children: [
      { path: '', redirect: 'online' },
      { path: 'offline', component: OverviewOffline, meta: { title: '线下培训证书' } },
      { path: 'online', component: OverviewOnline, meta: { title: '线上任务证书' } },
    ],
  },
  {
    path: 'code',
    component: CodeIndex,
    meta: { title: '证书编码管理' },
    children: [
      { path: '', redirect: 'management' },
      { path: 'management', component: CodeManagement },
    ],
  },
  {
    path: 'import',
    component: ImportIndex,
    meta: { title: '证书导入' },
    children: [
      { path: '', redirect: 'list' },
      { path: 'list', component: ImportList },
    ],
  },
  {
    path: 'template',
    component: TemplateIndex,
    meta: { title: '证书模版管理' },
    children: [
      {
        path: 'list',
        component: RouterView,
        children: [
          { path: '', component: TemplateList, meta: { title: '模版列表' } },
          { path: 'create', component: TemplateDetail, meta: { title: '添加模版' } },
          { path: 'edit', component: TemplateDetail, meta: { title: '修改模版' } },
          { path: 'detail', component: TemplateDetail, meta: { title: '模版详情' } },
        ],
      },
    ],
  },
];

export default routes;
