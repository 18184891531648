const Index = () => import(/* webpackChunkName: "DataCenter" */  '@views/dataCenter/Index.vue');
const DataOverview = () => import(/* webpackChunkName: "DataCenter" */  '@views/dataCenter/DataOverview.vue');
const ParticipationAnalysis = () => import(/* webpackChunkName: "DataCenter" */  '@views/dataCenter/ParticipationAnalysis.vue');
// const PeopleImport = () => import(/* webpackChunkName: "DataCenter" */  '@views/dataCenter/PeopleImport.vue');
const routes = [
  {
    path: 'dataCenter',
    component: Index,
    meta: { hideNav: true },
    children: [
      { path: '', redirect: 'dataOverview' },
      { path: 'dataOverview', component: DataOverview },
      { path: 'participationAnalysis', component: ParticipationAnalysis },
      // { path: 'people-import', component: PeopleImport },
    ],
  },
];

export default routes;
