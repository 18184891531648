<template>
  <div class="not-found-page">
    <h1 class="title">页面未找到</h1>

    <BackToPage></BackToPage>
  </div>
</template>

<script>
import BackToPage from '@components/BackToPage';

export default {
  name: 'NotFoundPage',
  components: {BackToPage},
  data() {
    return {
      redirectedFrom: '',
    }
  },
  created() {
    this.redirectedFrom = this.$route?.redirectedFrom || '';
    console.log('redirectedFrom: ', this.redirectedFrom);
  },
}
</script>

<style scoped lang="scss">
.not-found-page {
  padding: 12px;

  .title {
    text-align: center;
  }
}
</style>
