<template>
  <a-modal title="选择店类型"
           :visible="visible"
           width="600px"
           @ok="handleOk"
           @cancel="handleCancel">
    <div class="label no-margin">店类型</div>
    <a-checkbox-group v-model="params.store_type" :options="option.store_type"/>

    <div class="label">店状态</div>
    <a-checkbox-group v-model="params.store_state" :options="option.store_state"/>

    <div class="label">运营状态</div>
    <a-checkbox-group v-model="params.operation_state" :options="option.operation_state"/>

    <div class="label">开业年限</div>
    <a-checkbox-group v-model="params.store_age" :options="option.store_age"/>
  </a-modal>
</template>

<script>
export default {
  name: 'SelectShopTypeModal',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: [Object, Array], default: () => ({}) },
    option: { type: Object, default: () => ({}) },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(value) {
        this.setParamsValue(value);
      },
    },
  },
  data() {
    return {
      visible: false,
      params: {
        store_state: [],
        store_type: [],
        operation_state: [],
        store_age: [],
      },
    };
  },
  created() {
    this.setParamsValue(this.value);
  },
  methods: {
    setParamsValue(value) {
      this.params = {
        store_state: value?.store_state
          ? value?.store_state?.toString()?.split(',') ?? []
          : [],
        store_type: value?.store_type
          ? value?.store_type?.toString().split(',') ?? []
          : [],
        operation_state: value?.operation_state
          ? value?.operation_state?.toString().split(',') ?? []
          : [],
        store_age: value?.store_age
          ? value?.store_age?.toString().split(',') ?? []
          : [],
      };
    },
    onSuccess() {
      this.$emit('success', this.params);
    },
    handleOk() {
      const resultParams = {
        store_state: this.params.store_state.toString(),
        store_type: this.params.store_type.toString(),
        operation_state: this.params.operation_state.toString(),
        store_age: this.params.store_age.toString(),
      };
      this.$emit('success', resultParams);
      this.$emit('change', resultParams);
      this.visible = false;
    },
    handleCancel() {
      this.$emit('success', this.value);
      this.$emit('change', this.value);
      this.visible = false;
    },
    show(params) {
      this.visible = true;
      this.setParamsValue(params);
    },
  },
};
</script>

<style scoped lang="scss">
.label {
  margin: 20px 0 0 0;
  font-size: 15px;
  font-weight: 600;
}

::v-deep .ant-checkbox-wrapper {
  margin: 10px 10px 10px 0;
}
</style>
