<template>
  <div class="select-org">
    <div class="select-wrapper">
      <a-select v-model="selectedIds"
                :style="{ width: width }"
                ref="selectRef"
                :open="false"
                :allow-clear="false"
                :mode="'multiple'"
                :disabled="disabled"
                :show-arrow="false"
                :placeholder="placeholder || placeholderText"
                class="select"
                :class="{'selection-max-height': selectionMaxHeight}"
                @focus="showModal">
        <a-select-option :value="0">请选择组织机构</a-select-option>
        <a-select-option v-for="item in selectedData"
                         :key="item.id"
                         :value="item.id">
          {{ item.name }}
        </a-select-option>
      </a-select>

      <a-icon theme="filled"
              type="close-circle"
              class="clear-btn"
              :class="{ hidden: !selectedData || !selectedData.length }"
              @click="clear"/>
    </div>

    <a-button v-if="showSelectAllOrg" class="ml-10" @click="selectAllOrg">
      选择全部大区
    </a-button>

    <SelectOrgModal v-if="modalShown"
                    :data="parsedOptions"
                    :default-selected-ids="selectedIdsString"
                    :enabled-depth="enabledDepth"
                    :min="min"
                    :max="max"
                    :disable-merge-to-parent="disableMergeToParent"
                    :disable-merge-to-parent-level="disableMergeToParentLevel"
                    @confirm="select"
                    @close="closeModal"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import SelectOrgModal from '@components/select/SelectOrgModal';
import Common from '@/utils/Common';

export default {
  name: 'SelectOrg',
  components: { SelectOrgModal },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    // value: { type: String, default: '' },
    value: { type: String },
    disabled: { type: Boolean, default: false },
    placeholderText: { type: String, default: '请选择组织机构' },
    placeholder: { type: String, default: '' },
    width: { type: String, default: '' },
    showSelectAllOrg: { type: Boolean, default: false },
    data: { type: Array },
    enabledDepth: { type: Array, default: () => ([]) },
    min: { type: Number, default: 0 }, // 最少允许选择几个组织机构
    max: { type: Number, default: 0 }, // 最多允许选择几个组织机构
    // 同一父级下的直接子元素，全部选中时，不合并至选中该父级元素
    disableMergeToParent: { type: Boolean, default: false },
    disableMergeToParentLevel: { type: Array, default: undefined },
    selectionMaxHeight: { type: Boolean, default: true },
  },
  computed: {
    ...mapState({
      options: (state) => state.Common.orgTree,
      bigAreaIds: (state) => state.Common.bigAreaIds
    }),
    selectedIdsString() {
      return (this.selectedIds || []).join(',');
    },
    parsedOptions() {
      return this.data || this.options;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.setDefault();
      },
    },
    selectedIds: {
      handler(value, oldValue) {
        if ([...(value || [])].sort().join(',') === [...(oldValue || [])].sort().join(',')) {
          return;
        }
        this.$emit('change', value.toString(), this.getSelectedData());
        this.$emit('select', { data: this.getSelectedData() });
      },
    },
  },
  data() {
    return {
      modalShown: false,
      selectedData: [],
      selectedIds: [],
    };
  },
  methods: {
    setDefault() {
      if (!this.value) {
        this.selectedIds = [];
        return;
      }

      this.selectedIds = this.value.split(',').map(
        (i) => +i,
      ).filter(
        (i) => !!i,
      );
      this.selectedData = Common.getSelectedItems(
        this.selectedIds,
        this.parsedOptions,
        { id: 'id', name: 'name' },
      );
    },
    showModal() {
      if (this.disabled) {
        return;
      }
      this.modalShown = true;
    },
    closeModal() {
      this.modalShown = false;
    },

    clear() {
      this.select({ data: [] });
    },

    select(event) {
      this.selectedData = event?.data || [];
      this.selectedIds = this.selectedData.map((i) => i.id);

      const ids = this.selectedIds.join(',');

      this.closeModal();
      this.$emit('change', ids, event?.data || []);
      this.$emit('select', event);
    },

    getSelectedData(ids) {
      ids = ids || this.selectedIds.sort()?.toString();

      const orgTree = [...(this.parsedOptions || [])];
      const selectedData = [];

      for (let i = 0; i < orgTree.length; i++) {
        const item = orgTree[i];
        if (ids.split(',').map((i) => +i).includes(item.id)) {
          selectedData.push({ ...item });
        }

        if (item.children?.length) {
          orgTree.push(...item.children);
        }
      }
      return selectedData;
    },

    selectAllOrg() {
      this.select({
        data: this.getSelectedData((this.bigAreaIds || []).join(',')) || [],
      });
    },
  },
};
</script>

<style scoped lang="scss">
.select-org {
  display: flex;
}

.select-wrapper {
  position: relative;
  height: auto;
  line-height: 0;
}

.select {
  min-width: 350px;
  min-height: 32px;

  &.selection-max-height ::v-deep .ant-select-selection {
    overflow-y: auto;
    max-height: 120px;
  }
}

.select:hover + .clear-btn:not(.hidden) {
  display: inline-block;
}

.clear-btn {
  position: absolute;
  top: 14px;
  right: 11px;
  z-index: 1;
  display: none;
  width: 12px;
  height: 12px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.25);

  &:not(.hidden):hover {
    display: inline-block;
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
  }
}
</style>
