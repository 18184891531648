<template>
  <div class="player-control-bar" @click.stop="stopPropagation">
    <div v-if="showPlay" class="action play-pause-box">
      <img v-if="!playing" src="@/assets/player/play.png" alt="" class="play-pause-img" @click.stop="play"/>
      <img v-else src="@/assets/player/pause.png" alt="" class="play-pause-img" @click.stop="pause"/>
    </div>
    <div v-if="showTimePlay" class="display play-time-box">{{ playTimeStr }}</div>
    <div v-if="showProgress" class="display play-bar" ref="playBar" @mousedown="seekStart">
      <div class="play-bar-background" ref="playBarBackground"></div>
      <div class="play-bar-played" ref="played" :style="{'width': playPercentage}"></div>
    </div>
    <div v-if="showTimeDuration" class="display duration-box">{{ durationStr }}</div>
    <div v-if="showFullscreen" class="action full-screen-box">
      <img v-if="!isFullScreen" src="@/assets/player/full_screen_in.png" alt="" class="full-screen-img"
           @click.stop="fullScreenIn"/>
      <img v-else src="@/assets/player/full_screen_out.png" alt="" class="full-screen-img" @click.stop="fullScreenOut"/>
    </div>
  </div>
</template>

<script>
import Common from '@/utils/Common';

const hiddenList = ['play', 'progress', 'fullscreen', 'timePlay', 'timeDuration'];

export default {
  name: 'PlayerControlBar',
  props: {
    videoContainerId: {type: String, default: '', required: true},
    videoId: {type: String, default: '', required: true},
    hidden: {type: Array, default: () => [], validate: (list) => !list || list.some(i => hiddenList.includes(i))},
  },
  computed: {
    showPlay() {
      return !this.hidden || !this.hidden.includes('play');
    },
    showProgress() {
      return !this.hidden || !this.hidden.includes('progress');
    },
    showFullscreen() {
      return !this.hidden || !this.hidden.includes('fullscreen');
    },
    showTimePlay() {
      return !this.hidden || !this.hidden.includes('timePlay');
    },
    showTimeDuration() {
      return !this.hidden || !this.hidden.includes('timeDuration');
    },

    playTimeStr() {
      return Common.formatDurationTime(this.playTime);
    },
    durationStr() {
      return Common.formatDurationTime(this.duration);
    },
    playPercentage() {
      let per = this.playTime * 100 / this.duration;
      if (per <= 0) {
        per = 0;
      }
      if (per >= 100) {
        per = 100;
      }
      return per + '%';
    },
  },
  data() {
    return {
      showLog: true,

      videoContainerEl: null,
      videoEl: null,
      videoPlayTimer: null,

      isMousePressed: false,
      isVideoFocused: false,

      playing: false, // 正在播放
      isFullScreen: false, // 全屏 computed
      playTime: 0,
      duration: 0,

      volumeStep: 0.05, // 音量单次增减步长
      backwardStep: 5, // 单次后退时长，秒
      forwardStep: 5,
    }
  },
  created() {
    this.addDocumentListener();
  },
  mounted() {
    this.getVideoContainerEl();
    this.getVideoEl();
    this.addVideoListener();
    this.addVideoContainerListener();
  },
  beforeDestroy() {
    this.removeDocumentListener();
    this.removeVideoContainerListener();
  },
  methods: {
    log(info) {
      if (!this.showLog) {
        return;
      }
      console.log(info);
    },
    stopPropagation() {/**一个空函数*/
    },
    getVideoContainerEl() {
      this.videoContainerEl = document.getElementById(this.videoContainerId);
    },
    getVideoEl() {
      this.videoEl = document.getElementById(this.videoId);
      this.setVolume(1);
    },
    addDocumentListener() {
      document.addEventListener('fullscreenchange', this.fullScreenChangeHandler, false);
      document.addEventListener('mouseup', this.cancelActiveProgressBar, false);
      document.addEventListener('click', this.clearVideoFocus, false);
      document.addEventListener('keydown', this.seekByStep, false);
    },
    removeDocumentListener() {
      document.removeEventListener('fullscreenchange', this.fullScreenChangeHandler);
      document.removeEventListener('mouseup', this.cancelActiveProgressBar);
      document.removeEventListener('click', this.clearVideoFocus, false);
      document.removeEventListener('keydown', this.seekByStep, false);
    },
    addVideoContainerListener() {
      if (!this.videoContainerEl) {
        return this.log('[addVideoContainerListener] failed. No video container element found.');
      }
      this.videoContainerEl.addEventListener('mouseleave', this.cancelMousePress, false);
      this.videoContainerEl.addEventListener('mousemove', this.seekProgress, false);
      this.videoContainerEl.addEventListener('click', this.setVideoFocus, false);
      this.videoContainerEl.addEventListener('click', this.togglePlay, false);
    },
    removeVideoContainerListener() {
      if (!this.videoContainerEl) {
        return this.log('[addVideoContainerListener] failed. No video container element found.');
      }
      this.videoContainerEl.removeEventListener('mouseleave', this.cancelMousePress);
      this.videoContainerEl.removeEventListener('mousemove', this.seekProgress);
      this.videoContainerEl.removeEventListener('click', this.setVideoFocus, false);
      this.videoContainerEl.removeEventListener('click', this.togglePlay, false);
    },
    addVideoListener() {
      if (!this.videoEl) {
        return this.log('[addVideoListener] listen failed. No video element found.');
      }
      this.videoEl.ondurationchange = () => {
        this.duration = this.videoEl.duration || 0;
      };
      this.videoEl.onplay = () => {
        this.playing = true;
        this.playTime = this.videoEl.currentTime || 0;
        this.setVideoPlayTimer();
      };
      this.videoEl.onended = () => {
        this.pause();
      };
    },
    setPlayTime(time) {
      if (!this.videoEl) {
        return this.log('[setPlayTime] failed. No video element found.');
      }
      time = time <= 0 ? 0 : (time >= this.duration ? this.duration : time);
      this.videoEl.currentTime = time;
      this.playTime = time;
    },

    fullScreenChangeHandler() {
      this.isFullScreen = !!document?.fullscreenElement;
    },
    cancelActiveProgressBar() {
      if (this.isMousePressed) {
        this.play();
      }
      this.isMousePressed = false;
      if (!this.videoEl) {
        return this.log('[cancelActiveProgressBar] failed. No video element found.');
      }
      if (this.isMousePressed && (this.videoEl.paused || this.videoEl.ended)) {
        this.play();
      }
    },

    clearVideoFocus() {
      this.isVideoFocused = false;
    },
    setVideoFocus(event) {
      this.isVideoFocused = true;
      event.stopPropagation();
      event.cancelBubble = true;
    },

    setVideoPlayTimer() {
      this.clearVideoPlayTimer();
      this.videoPlayTimer = setInterval(() => {
        this.playTime = this.videoEl.currentTime || 0;
      }, 100);
    },
    clearVideoPlayTimer() {
      if (this.videoPlayTimer) {
        clearInterval(this.videoPlayTimer);
        this.videoPlayTimer = null;
      }
    },

    reset() {
      this.pause();
      if (!this.videoEl) {
        this.playTime = 0;
        this.duration = 0;
        return this.log('[reset] No video element found.');
      }
      this.videoEl.currentTime = 0;
      this.playTime = 0;
    },

    togglePlay() {
      this.playing ? this.pause() : this.play();
    },
    play() {
      if (!this.videoEl) {
        return this.log('[PlayerControlBar] play failed. No video element found.');
      }
      this.videoEl.play();
      this.playing = true;
    },
    pause() {
      if (!this.videoEl) {
        return this.log('[PlayerControlBar] pause failed. No video element found.');
      }
      this.videoEl.pause();
      this.playing = false;
      this.clearVideoPlayTimer();
    },

    getVolume() {
      if (!this.videoEl) {
        this.log('[setVolume] failed. No video element found.');
        return 0;
      }
      return this.videoEl.volume;
    },
    setVolume(volume) {
      if (!this.videoEl) {
        return this.log('[setVolume] failed. No video element found.');
      }
      volume = volume < 1 ? (volume > 0 ? volume : 0) : 1;
      this.videoEl.volume = volume;
    },

    toggleFullScreen() {
      this.isFullScreen ? this.fullScreenOut() : this.fullScreenIn();
    },
    fullScreenIn() {
      if (!this.videoContainerEl) {
        return this.log('[fullScreenIn] full screen in failed. No video container element found.');
      }
      if (this.isFullScreen) {
        return this.log('[fullScreenIn] full screen in failed. Already in full screen.');
      }

      const element = document.documentElement;

      if (element.requestFullscreen) {
        this.videoContainerEl.requestFullscreen();
      } else if (element.webkitRequestFullScreen) {
        this.videoContainerEl.webkitRequestFullScreen();
      } else if (element.mozRequestFullScreen) {
        this.videoContainerEl.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        this.videoContainerEl.msRequestFullscreen();
      }

      this.videoContainerEl.className = this.videoContainerEl.className + ' fullscreen';
    },
    fullScreenOut() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }

      if (!this.videoContainerEl) {
        return this.log('[fullScreenIn] full screen in failed. No video container element found.');
      }
      this.videoContainerEl.className = this.videoContainerEl.className.replace(/\s*fullscreen\s*/g, ' ');
    },

    seek(offsetX) {
      const containerWidth = this.$refs.playBar?.offsetWidth || 0;
      offsetX = offsetX || containerWidth * this.playTime / this.duration;
      if (!offsetX) {
        return this.log('[seek] failed. No offsetX.');
      }
      if (offsetX <= 0) {
        offsetX = 0;
      }
      if (offsetX >= containerWidth) {
        offsetX = containerWidth;
      }
      this.setPlayTime(offsetX * this.duration / containerWidth);
    },
    seekStart(ev) {
      this.setMousePress();
      ev = ev || window.event;
      this.seek(ev?.offsetX);
    },
    seekProgress(ev) {
      if (!this.isMousePressed) {
        return this.log('[seekProgress] returned and do nothing. Mouse is not pressed.');
      }
      ev = ev || window.event;
      const target = ev.target || ev.srcElement;
      const playBar = this.$refs.playBar;
      const playBarBackground = this.$refs.playBarBackground;
      const played = this.$refs.played;
      if (target !== playBar && target !== playBarBackground && target !== played) {
        return this.log('[seekProgress] return. Out of range.');
      }
      this.seek(ev?.offsetX);
    },
    backward() {
      const time = this.playTime - this.backwardStep;
      this.setPlayTime(time > 0 ? time : 0);
    },
    forward() {
      const time = this.playTime + this.forwardStep;
      this.setPlayTime(time < this.duration ? time : this.duration);
    },
    setMousePress() {
      this.isMousePressed = true;
    },
    cancelMousePress() {
      this.isMousePressed = false;
    },

    seekByStep(event) {
      event = event || window.event;
      const keyCode = event.keyCode;
      switch (keyCode) {
        case 13:// 回车键
          this.toggleFullScreen();
          break;
        case 32:// 空格键
          this.togglePlay();
          break;
        case 37:// 键盘向左键
          this.backward();
          break;
        case 39:// 键盘向右键
          this.forward();
          break;
        case 38:// 键盘向上键
          this.setVolume(this.getVolume() + this.volumeStep);
          break;
        case 40:// 键盘向下键
          this.setVolume(this.getVolume() - this.volumeStep);
          break;
      }
    },
  },
}
</script>

<style scoped lang="scss">
.player-control-bar {
  $controlBarHeight: 72px;

  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: $controlBarHeight;
  padding: 0 5px;
  background: rgba(0, 0, 0, 0.25);

  .play-time-box, .duration-box {
    margin: 0 4px;
    font-size: 16px;
    color: #FFFFFF;
  }

  .play-pause-img {
    margin: 0 16px 0 18px;
    width: 14px;
    height: 17px;
  }

  .full-screen-img {
    margin: 0 18px 0 16px;
    width: 17px;
    height: 17px;
  }

  .play-bar {
    position: relative;
    flex: 1 1 100%;
    height: $controlBarHeight;
    margin: 0 5px;
  }

  .play-bar-background {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 9;
    height: 2px;
    width: 100%;
    background-color: #FFFFFF;
    cursor: pointer;
  }

  .play-bar-played {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 99;
    width: 0;
    height: 2px;
    background-color: $info02;
    cursor: pointer;

    &:after {
      $size: 8px;
      content: '';

      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(50%, -50%);

      display: block;
      width: $size;
      height: $size;
      border-radius: $size;
      background-color: #FFFFFF;
    }
  }
}
</style>
