<template>
  <BlockLoading :zoom="2"></BlockLoading>
</template>

<script>
import { setToken } from '@/utils/Token';

import BlockLoading from '@components/BlockLoading';

export default {
  name: 'Transition',
  components: { BlockLoading },
  created() {
    const token = this.$route?.query?.token || '';
    const queryRoute = this.$route?.query?.route || '';
    const queryRouteQueryStr = decodeURIComponent(queryRoute).split('?')[1];

    let route = '';
    let query = {};
    setToken(token);
    this.$store.commit('User/updateToken', token);

    if (!queryRoute) {
      route = '/index';
    } else {
      route = `${ queryRoute }`;
    }

    if (queryRouteQueryStr) {
      const queries = queryRouteQueryStr.split('&');
      queries.forEach((i) => (query[i.split('=')[0]] = i.split('=')[1]));
    }

    if (!token) {
      if (route.indexOf('/account/reset') > -1) {
        this.$router.replace({ path: '/account/reset' });
      } else {
        this.$router.replace({ path: '/account/login' });
      }
      return;
    }
    this.$router.replace({ path: route, query });
  },
  methods: {},
};
</script>
