const Statistics = {
  namespaced: true,
  state: {
    defaultSpeechSkillQuery: {
      startDateTime: '',
      endDateTime: '',
      skillType: 1,
      examName: '',
      skillCate1: undefined,
      skillCate2: undefined,
      skill: undefined,
      bigArea: undefined,
      org: '',
      job: '',
      passStatus: 0,
      filterTxt: '',
      shopType: { store_state: 1 },

      skillList: [],
      jobList: [],
      orgList: [],
      bigAreaList: [],
    },
    defaultSpeechGroupQuery: {
      startDateTime: '',
      endDateTime: '',
      examType: undefined,
      examName: '',
      group: undefined,
      groupName: '',
      bigArea: undefined,
      job: '',
      shopType: { store_state: 1 },
    },

    speechSkillQuery: {},
    speechGroupQuery: {},

    studentId: '',
    studentName: '',
    groupTitle: '',
  },
  mutations: {
    setSpeechSkillQuery(state, query) {
      state.speechSkillQuery = Object.assign({}, state.defaultSpeechSkillQuery, state.speechSkillQuery, query || {});
    },
    resetSpeechSkillQuery(state) {
      state.speechSkillQuery = Object.assign({}, state.defaultSpeechSkillQuery);
    },

    setSpeechGroupQuery(state, query) {
      state.speechGroupQuery = Object.assign({}, state.defaultSpeechGroupQuery, state.speechGroupQuery, query || {});
    },
    resetSpeechGroupQuery(state) {
      state.speechGroupQuery = Object.assign({}, state.defaultSpeechGroupQuery);
    },
    removeSpeechGroupQueryAll(state) {
      state.speechGroupQuery = {};
    },
    updateStudentInfo(state, info) {
      state.studentId = info?.id;
      state.studentName = info?.name;
    },

    updateGroupTitle(state, groupTitle) {
      state.groupTitle = groupTitle;
    },
  },
  actions: {},
};

export default Statistics;
