import Vue from 'vue';
import VueRouter from 'vue-router';

import NotFoundPage from '@views/error/NotFoundPage';
import Transition from '@views/Transition';
import TestPage from '@views/TestPage';

import viewRoute from './view';
import accountRoute from './account';
import frameRoute from './frame';

Vue.use(VueRouter);

const routes = [
  { path: '', redirect: '/transition' },
  { path: '/transition', component: Transition },

  accountRoute,
  viewRoute,
  frameRoute,

  { path: '*', component: NotFoundPage },
  { path: '/test', component: TestPage },
  { path: '/404', component: NotFoundPage },
  { path: '*', redirect: '/404' },
];

const createRouter = () => new VueRouter({
  scrollBehavior: () => ({ y: 0 }),
  routes: routes,
});

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
