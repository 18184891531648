import BroadcastStatistics from '@/router/view/data-statistics/BroadcastStatistics';

const Index = () => import(/* webpackChunkName: "DataStatistics" */  '@views/data-statistics/Index');

const scriptRouter = [
  {
    path: 'data-statistics',
    component: Index,
    children: [
      { path: '', redirect: 'broadcast-overview' },
      ...BroadcastStatistics,
    ],
  },
];

export default scriptRouter;
