const Index = () => import(/* webpackChunkName: "Statistics" */  '@views/statistics/course/index.vue');
const VisitPersonTime = () => import(/* webpackChunkName: "Statistics" */  '@views/statistics/course/VisitPersonTime.vue');

const routes = [
  {
    path: 'course',
    component: Index,
    meta: { title: '课程查询' },
    children: [
      { path: '', redirect: 'person-time' },
      { path: 'person-time', component: VisitPersonTime, meta: { title: '学习人次查询' } },
    ],
  },
];

export default routes;
