import request from '@/utils/Request';

// 删除岗位 - 日志列表
export function getDeletedPostRecord(params) {
  return request({
    url: '/manage/admin/postlog/show',
    method: 'get',
    params,
  });
}

// 删除岗位 - 被删除的岗位列表
export function getDeletedPostList(params) {
  return request({
    url: '/manage/admin/postlog/jobs',
    method: 'get',
    params,
  });
}

// 删除岗位 - 操作人列表
export function getDeletedPostUser(params) {
  return request({
    url: '/manage/admin/postlog/user',
    method: 'get',
    params,
  });
}

