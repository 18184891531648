import request from '@/utils/RequestNoLimits';

// 数据中心课程标签
export function getTagCourses(params) {
  return request({
    url: `/public/conf/getTagsCourse`,
    method: 'get',
    params,
  });
}

//店类型
export function getStoreType(params) {
  return request({
    url: `/datacenter/admin/participationrate/storeType`,
    method: 'get',
    params,
  });
}



export * from './DataOverview';
export * from './Participation';
export * from './PeopleImport';





