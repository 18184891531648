<template>
  <a-select v-model="selectedIds"
            ref="selectRef"
            :mode="mode"
            :allow-clear="allowClear"
            :show-search="true"
            :auto-clear-search-value="false"
            :get-popup-container="getContainer"
            :filter-option="false"
            :disabled="disabled"
            :placeholder="placeholder"
            class="select"
            @focus="resetSearch"
            @blur="resetSearch"
            @popupScroll="onScroll"
            @change="select"
            @search="onSearch">
    <a-select-option v-if="mode === 'multiple' && allowSelectAll" :value="0" @click="selectAll">全选</a-select-option>
    <a-select-option v-for="(item) in filteredOptions" :key="item[options.id]" :value="item[options.id]">
      {{ item[options.name] }}
    </a-select-option>
  </a-select>
</template>

<script>

export default {
  name: 'SelectBase',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    mode: {
      type: String,
      default: 'multiple',
      validator: (val) => ['multiple', 'default'].includes(val),
    },
    value: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    allowClear: { type: Boolean, default: true },
    allowSelectAll: { type: Boolean, default: true },
    placeholder: { type: String, default: '请选择' },
    list: { type: Array, default: () => [] },
    options: { type: Object, default: () => ({ id: 'id', name: 'name' }) },
    fixedTags: {
      type: Array, default: () => [
        'a-form-model-item',
        'AFormModelItem',
        'a-form-item',
        'AFormItem',
      ],
    },
  },
  computed: {
    getContainer() {
      // 1. 下拉框跟随输入框滚动
      // 2. 当用于 form-model-item 时，保证 mode === 'multiple' 时，选中选项后，保证下拉框不消失
      return () => {
        let parent = this.$refs.selectRef.$parent;
        let count = 9999;
        while (
          parent
          && (!parent?.$vnode?.componentOptions?.tag || !this.fixedTags.includes(parent.$vnode.componentOptions.tag))
          && count > 0
          ) {
          parent = parent.$parent;
          count -= 1;
        }
        return (count > 0 ? parent?.$el : null) || this.$root?.$el || document.body || null;
      };
    },
  },
  watch: {
    value() {
      this.initSelectionData();
    },
    list() {
      this.initSelectionData();
    },
  },
  data() {
    return {
      selectedIds: [],
      isAllClicked: false,

      page: 1,
      perPage: 50,
      filterTxt: '',
      filteredOptions: [],
    };
  },
  created() {
    this.initSelectionData();
  },
  methods: {
    initSelectionData() {
      this.setDefault();
      this.getFilteredOptions();
    },

    resetSearch() {
      this.onSearch('');
    },
    onSearch(value) {
      this.filterTxt = value;
      this.page = 1;
      this.getFilteredOptions();
    },

    getFilteredOptions() {
      this.filteredOptions = this.list.filter((option, index) => {
        if (this.mode === 'multiple') {
          if (this.selectedIds.map(i => +i).indexOf(+option[this.options.id]) > -1) {
            return true;
          }
        } else {
          if (this.selectedIds + '' === option[this.options.id] + '') {
            return true;
          }
        }

        if (!this.filterTxt && index < this.page * this.perPage) {
          return true;
        }
        return this.filterTxt && option.title.indexOf(this.filterTxt) > -1;
      });
    },

    onScroll(ev) {
      const target = ev.currentTarget || ev.target || ev.srcElement;
      if (!target) {
        return;
      }

      const height = target.clientHeight || 250;
      const scrollTop = target.scrollTop || 0;
      const scrollHeight = target.scrollHeight || 250;

      if (scrollTop + 1.2 * height > scrollHeight && this.page * this.perPage < this.list.length) {
        this.loadMore();
      }
    },

    loadMore() {
      this.page += 1;
      this.getFilteredOptions();
    },

    selectAll() {
      this.isAllClicked = true;
    },

    setDefault() {
      if (!this.value) {
        this.selectedIds = this.mode === 'multiple' ? [] : undefined;
        return;
      }
      const ids = this.value ? this.value.split(',') : [];
      this.selectedIds = this.mode === 'multiple' ? ids.map(i => +i) : (+ids[0] || undefined);
    },

    select() {
      if (!this.filteredOptions?.length) {
        return;
      }

      if (this.isAllClicked && this.mode === 'multiple') {
        this.selectedIds = this.filteredOptions.map(op => op[this.options.id]).filter((i) => !!i);
      }
      this.isAllClicked = false;

      const selectedIds = this.mode === 'multiple' ? this.selectedIds : [this.selectedIds];

      const ids = selectedIds.filter(i => !!i);
      this.$emit('select', ids);
      this.$emit('change', ids.join(','));
    },
  },
};
</script>

<style scoped lang="scss">
.select {
  min-width: 350px;
}
</style>
