import PackageRoutes from './package';

const Index = () => import(/* webpackChunkName: "Course" */ '@views/course/Index');

const routes = [
  {
    path: 'course',
    component: Index,
    children: [
      { path: '', redirect: 'package' },
      { path: 'course-package', redirect: 'package' },

      ...PackageRoutes,
    ],
  },
];

export default routes;
