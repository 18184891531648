const StatisticsGroupDetail = () => import(/* webpackChunkName: "Statistics" */ '@views/statistics/speechSkill/Group/Detail');
const StatisticsGroupArea = () => import(/* webpackChunkName: "Statistics" */ '@views/statistics/speechSkill/Group/Area');
const StatisticsGroupDistributor = () => import(/* webpackChunkName: "Statistics" */ '@views/statistics/speechSkill/Group/Distributor');
const StatisticsGroupStudent = () => import(/* webpackChunkName: "Statistics" */ '@views/statistics/speechSkill/Group/Student');
const StatisticsGroupStudentData = () => import(/* webpackChunkName: "Statistics" */ '@views/statistics/speechSkill/Group/StudentDetail');


const StatisticsGroupRouter = [
  {
    path: 'groupDetail',
    component: StatisticsGroupDetail,
    children: [
      {
        path: '',
        redirect: 'area',
      },
      {
        name: 'StatisticsGroupArea',
        path: 'area',
        component: StatisticsGroupArea,
      },
      {
        name: 'StatisticsGroupDistributor',
        path: 'distributor',
        component: StatisticsGroupDistributor,
      },
      {
        name: 'StatisticsGroupStudent',
        path: 'student',
        component: StatisticsGroupStudent,
      },
    ],
  },
  {
    name: 'StatisticsGroupStudentData',
    path: 'studentDetail',
    component: StatisticsGroupStudentData,
  },
];

export default StatisticsGroupRouter;
