const Index = () => import(/* webpackChunkName: "download" */  '@views/download/Index.vue')

const RecordIndex = () => import(/* webpackChunkName: "download" */  '@views/download/record/Index.vue')
const List = () => import(/* webpackChunkName: "download" */  '@views/download/record/List.vue')

const routes = [
  {
    path: 'download',
    component: Index,
    meta: { title: '下载管理', hideNav: true },
    children: [
      { path: '', redirect: 'record' },
      {
        path: 'record',
        component: RecordIndex,
        children: [
          { path: '', redirect: 'list' },
          { path: 'list', component: List, meta: { title: '下载记录' } }
        ]
      }
    ]
  }
]

export default routes
