import request from '@/utils/Request';

// 获取贴片/banner 列表
export function getStickerList(params) {
  return request({
    url: '/ad/admin/alert',
    method: 'get',
    params,
  });
}

// 获取贴片/banner 详细
export function getStickerDetail(params) {
  return request({
    url: `/ad/admin/alert/${params?.id}`,
    method: 'get',
  });
}

// 保存贴片/banner 详情
export function saveSticker(data) {
  return request({
    url: `/ad/admin/alert`,
    method: 'post',
    data,
  });
}

// 贴片/banner 上架
export function pullOn(params) {
  return request({
    url: `/ad/admin/alert/status/${params?.id}`,
    method: 'post',
    data: {status: 'on'},
  });
}

// 贴片/banner 下架
export function pullOff(params) {
  return request({
    url: `/ad/admin/alert/status/${params?.id}`,
    method: 'post',
    data: {status: 'off'},
  });
}

// 贴片/banner 删除
export function deleteSticker(params) {
  return request({
    url: `/ad/admin/alert/status/${params?.id}`,
    method: 'post',
    data: {status: 'del'},
  });
}

// 获取点击记录
export function stickerActiveRecord(id, params) {
  return request({
    url: `/ad/admin/alert/log/${id}`,
    method: 'get',
    params,
  });
}
