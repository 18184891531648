import request from '@/utils/Request';

// 获取话术列表
export function getSkillList(params) {
  return request({
    url: '/speechskill/admin/skill/index',
    method: 'get',
    params,
  });
}

// 话术-更新某个字段信息
export function updateSkillField(data) {
  return request({
    url: '/speechskill/admin/skill/updfield',
    method: 'post',
    data,
  });
}

// 话术-上架
export function pullOnSkill(data) {
  data.field = 'status';
  data.value = 1;
  return request({
    url: '/speechskill/admin/skill/updfield',
    method: 'post',
    data,
  });
}

// 话术-下架
export function pullOffSkill(data) {
  data.field = 'status';
  data.value = 2;
  return request({
    url: '/speechskill/admin/skill/updfield',
    method: 'post',
    data,
  });
}

// 话术-批量上架
export function batchPullOnSkill(data) {
  data.field = 'status';
  data.value = 1;
  return request({
    url: '/speechskill/admin/skill/batch-updfield',
    method: 'post',
    data,
  });
}

// 话术-批量下架
export function batchPullOffSkill(data) {
  data.field = 'status';
  data.value = 2;
  return request({
    url: '/speechskill/admin/skill/batch-updfield',
    method: 'post',
    data,
  });
}

// 话术-删除
export function deleteSkill(data) {
  return request({
    url: '/speechskill/admin/skill/delete',
    method: 'post',
    data,
  });
}

// 话术-详情
export function getSkillDetail(params) {
  return request({
    url: '/speechskill/admin/skill/detail',
    method: 'get',
    params,
  });
}

// 话术-保存基本信息
export function saveSkillBase(data) {
  return request({
    url: '/speechskill/admin/skill/save-base',
    method: 'post',
    data,
  });
}

// 话术-保存练习设置
export function saveSkillPractice(data) {
  return request({
    url: '/speechskill/admin/skill/save-practice',
    method: 'post',
    data,
  });
}

// 话术-配置校验
export function getSkillCompleteStatus(params) {
  return request({
    url: '/speechskill/admin/skill/complete-status',
    method: 'get',
    params
  });
}

// 话术-获取话术机构，岗位
export function getPostOrgBySkill(params) {
  return request({
    url: '/speechskill/admin/skill/orgpost',
    method: 'get',
    params,
  });
}

// 话术-批量修改信息
export function batchSaveSkillBase(data) {
  return request({
    url: '/speechskill/admin/skill/batch-save-base',
    method: 'post',
    data,
  });
}
