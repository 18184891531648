<template>
  <router-view/>
</template>

<script>
import { setToken } from '@/utils/Token';

export default {
  name: 'Index',
  created() {
    this.$store.dispatch('Common/getJobs');
    this.$store.dispatch('Common/getOrgTree');

    const token = this.$route.query.token || localStorage.getItem('token') || sessionStorage.getItem('token') || '';
    setToken(token);
    this.$store.commit('User/updateToken', token);
  },
};
</script>

<style scoped>

</style>
