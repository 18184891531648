import {
  getSkillGroupList,
  getSkillGroup,
} from '@/views/speechcraft/group/api';

const Common = {
  namespaced: true,
  state: {
    readOnly: true,
    assessmentId: 0,
    assessment: {},
    assessmentList: [],
  },
  mutations: {
    updateReadOnly(state, readOnly) {
      state.readOnly = !!readOnly;
    },
    updateAssessmentId(state, id) {
      state.assessmentId = id || 0;
    },
    updateAssessment(state, data) {
      state.assessment = data || {};
    },
    updateAssessmentList(state, list) {
      state.assessmentList = list || [];
    },
  },
  actions: {
    async getAssessment({ commit }, id) {
      if (!id) {
        commit('updateReadOnly', false);
        commit('updateAssessmentId', null);
        commit('updateAssessment', {});
        return;
      }
      const data = await getSkillGroup({ id });
      if (!data || data.error_code) {
        return {};
      }
      const result = data?.data || {};
      commit('updateReadOnly', result.status === 1);
      commit('updateAssessmentId', id);
      commit('updateAssessment', result);
    },

    async getAssessmentListForSelect({ commit }, value) {
      return getSkillGroupList({
        title: value,
        page: 1,
        per_page: 50,
      }).then((data) => {
        const result = data?.data || [];
        commit('updateAssessmentList', result);
      }).catch(() => {
      });
    },
  },
};

export default Common;
