const ConfigDetails = () => import(/* webpackChunkName: "TrainingCenter" */  '@views/trainingCenter/offline/config/Index');
const BasicConfiguration = () => import(/* webpackChunkName: "TrainingCenter" */  '@views/trainingCenter/offline/config/basicConfiguration');
const PageInfoConfiguration = () => import(/* webpackChunkName: "TrainingCenter" */  '@views/trainingCenter/offline/config/pageInfoConfiguration');
const PreviewContentsConfiguration = () => import(/* webpackChunkName: "TrainingCenter" */  '@views/trainingCenter/offline/config/previewContentsConfiguration');
const SignUpConfiguration = () => import(/* webpackChunkName: "TrainingCenter" */  '@views/trainingCenter/offline/config/signUpConfiguration');
const InstructorAppointment = () => import(/* webpackChunkName: "TrainingCenter" */  '@views/trainingCenter/offline/config/instructorAppointment');
const ClassConfiguration = () => import(/* webpackChunkName: "TrainingCenter" */  '@views/trainingCenter/offline/config/classConfiguration');
const CheckWorkAttendanceConfiguration = () => import(/* webpackChunkName: "TrainingCenter" */  '@views/trainingCenter/offline/config/checkWorkAttendanceConfiguration');
const LiveClassConfiguration = () => import(/* webpackChunkName: "TrainingCenter" */  '@views/trainingCenter/offline/config/liveClassConfiguration');
const Exam = () => import(/* webpackChunkName: "TrainingCenter" */  '@views/trainingCenter/offline/config/Exam');
const SurveyConfiguration = () => import(/* webpackChunkName: "TrainingCenter" */  '@views/trainingCenter/offline/config/surveyConfiguration');
const MaterialConfiguration = () => import(/* webpackChunkName: "TrainingCenter" */  '@views/trainingCenter/offline/config/materialConfiguration');
const TrainingMaterialsUpload = () => import(/* webpackChunkName: "TrainingCenter" */  '@views/trainingCenter/offline/config/trainingMaterialsUpload');
const CompletionConditionsConfiguration = () => import(/* webpackChunkName: "TrainingCenter" */  '@views/trainingCenter/offline/config/completionConditionsConfiguration');
const TrainPlanConfig = () => import(/* webpackChunkName: "TrainingCenter" */  '@views/trainingCenter/offline/config/trainPlanConfig');

const configRouter = [
  {
    path: 'config',
    component: ConfigDetails,
    meta: { title: '编辑培训信息' },
    children: [
      {
        path: '',
        redirect: 'trainSign',
      },
      {
        name: 'BasicConfiguration',
        path: 'basicConfiguration',
        component: BasicConfiguration,
      },
      {
        name: 'PageInfoConfiguration',
        path: 'pageInfoConfiguration',
        component: PageInfoConfiguration,
      },
      {
        name: 'PreviewContentsConfiguration',
        path: 'previewContentsConfiguration',
        component: PreviewContentsConfiguration,
      },
      {
        name: 'SignUpConfiguration',
        path: 'signUpConfiguration',
        component: SignUpConfiguration,
      },
      {
        name: 'InstructorAppointment',
        path: 'instructorAppointment',
        component: InstructorAppointment,
      },
      {
        name: 'ClassConfiguration',
        path: 'classConfiguration',
        component: ClassConfiguration,
      },
      {
        name: 'CheckWorkAttendanceConfiguration',
        path: 'checkWorkAttendanceConfiguration',
        component: CheckWorkAttendanceConfiguration,
      },
      {
        name: 'LiveClassConfiguration',
        path: 'liveClassConfiguration',
        component: LiveClassConfiguration,
      },
      {
        name: 'Exam',
        path: 'exam',
        component: Exam,
      },
      {
        name: 'SurveyConfiguration',
        path: 'surveyConfiguration',
        component: SurveyConfiguration,
      },
      {
        name: 'MaterialConfiguration',
        path: 'materialConfiguration',
        component: MaterialConfiguration,
      },
      {
        name: 'TrainingMaterialsUpload',
        path: 'trainingMaterialsUpload',
        component: TrainingMaterialsUpload,
      },
      {
        name: 'CompletionConditionsConfiguration',
        path: 'completionConditionsConfiguration',
        component: CompletionConditionsConfiguration,
      },
      {
        name: 'TrainPlanConfig',
        path: 'trainPlanConfig',
        component: TrainPlanConfig,
      },
    ],
  },
];

export default configRouter;
