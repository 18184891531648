const Index = () => import(/* webpackChunkName: "competition" */  '@/views/competition/skill/index.vue');

const RouterView = () => import(/* webpackChunkName: "competition" */  '@/views/routerView/Index.vue');

const ConfigStage = () => import(/* webpackChunkName: "competition" */  '@/views/competition/skill/config/stage.vue');
const ConfigPicture = () => import(/* webpackChunkName: "competition" */  '@/views/competition/skill/config/picture.vue');
const ConfigOpus = () => import(/* webpackChunkName: "competition" */  '@/views/competition/skill/config/opus.vue');
const ConfigExam = () => import(/* webpackChunkName: "competition" */  '@/views/competition/skill/config/exam.vue');
const ConfigShowcase = () => import(/* webpackChunkName: "competition" */  '@/views/competition/skill/config/showcase.vue');

const ConfigGrade = () => import(/* webpackChunkName: "competition" */  '@/views/competition/skill/config/grade.vue');

const StatisticsMaxScore = () => import(/* webpackChunkName: "competition" */  '@/views/competition/skill/statistics/maxScore.vue');
const StatisticsAllScore = () => import(/* webpackChunkName: "competition" */  '@/views/competition/skill/statistics/allScore.vue');
const StatisticsPersonalQuiz = () => import(/* webpackChunkName: "competition" */  '@/views/competition/skill/statistics/personalQuiz.vue');
const StatisticsQuizAnalysis = () => import(/* webpackChunkName: "competition" */  '@/views/competition/skill/statistics/quizAnalysis.vue');
const StatisticsLearnProcess = () => import(/* webpackChunkName: "competition" */  '@/views/competition/skill/statistics/learnProcess.vue');

const routes = [
  {
    path: 'skillConfig',
    component: Index,
    meta: { title: '技能大赛' },
    children: [
      { path: '', redirect: './stage' },
      {
        path: '',
        component: RouterView,
        meta: { title: '配置' },
        children: [
          { path: '', redirect: './stage' },

          { path: 'stage', component: ConfigStage, meta: { title: '首页阶段配置' } },
          { path: 'picture', component: ConfigPicture, meta: { title: '页面头图配置' } },
          { path: 'opus', component: ConfigOpus, meta: { title: '作品上传配置' } },
          { path: 'exam', component: ConfigExam, meta: { title: '考试入口配置' } },
          { path: 'showcase', component: ConfigShowcase, meta: { title: '荣耀展台配置' } },

          { path: 'grade', component: ConfigGrade, meta: { title: '上传作品打分' } },
        ],
      },
    ],
  },
  {
    path: 'skillStatistics',
    component: Index,
    meta: { title: '技能大赛' },
    children: [
      { path: '', redirect: './learnProcess' },
      {
        path: '',
        component: RouterView,
        meta: { title: '查询' },
        children: [
          { path: '', redirect: './learnProcess' },

          { path: 'maxScore', component: StatisticsMaxScore, meta: { title: '最高成绩导出' } },
          { path: 'allScore', component: StatisticsAllScore, meta: { title: '全部成绩导出' } },
          { path: 'personalQuiz', component: StatisticsPersonalQuiz, meta: { title: '个人全部题目导出' } },
          { path: 'quizAnalysis', component: StatisticsQuizAnalysis, meta: { title: '试题分析' } },
          { path: 'learnProcess', component: StatisticsLearnProcess, meta: { title: '课程学习情况查询' } },
        ],
      },

    ],
  },
];

export default routes;
