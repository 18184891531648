import StatisticsSkill from '@/router/view/statistics/speechSkill/Skill';
import StatisticsGroup from '@/router/view/statistics/speechSkill/Group';
import StatisticsCertificate from '@/router/view/statistics/certificate/Index';

import StatisticsParticipationRouter from '@/router/view/statistics/participation/Index';
import CourseRoutes from './course';
import SpeechcraftRoutes from './speechcraft';

const Index = () => import(/* webpackChunkName: "Statistics" */  '@views/statistics/Index');

const routes = [
  {
    path: 'statistics',
    component: Index,
    children: [
      { path: '', redirect: 'participation' },
      // to be removed, next 2 lines
      ...StatisticsSkill,
      ...StatisticsGroup,
      // new routes
      ...SpeechcraftRoutes,
      ...StatisticsCertificate,

      ...StatisticsParticipationRouter,

      ...CourseRoutes,
    ],
  },
];

export default routes;
