const Index = () => import(/* webpackChunkName: "User" */  '@/views/user/Index.vue');

const routes = [
  {
    path: 'user',
    component: Index,
    meta: { title: '用户配置' },
    children: [],
  },
];

export default routes;
