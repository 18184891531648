const Index = () => import(/* webpackChunkName: "speechcraft" */  '@/views/speechcraft/group/Index.vue');

const List = () => import(/* webpackChunkName: "speechcraft" */ '@/views/speechcraft/group/List.vue');

const Detail = () => import(/* webpackChunkName: "speechcraft" */ '@/views/speechcraft/group/detail/Detail.vue');
const Brief = () => import(/* webpackChunkName: "speechcraft" */ '@/views/speechcraft/group/detail/Brief.vue');
const Topic = () => import(/* webpackChunkName: "speechcraft" */ '@/views/speechcraft/group/detail/Topic.vue');
const PatternSetting = () => import(/* webpackChunkName: "speechcraft" */ '@/views/speechcraft/group/detail/PatternSetting.vue');
const Verification = () => import(/* webpackChunkName: "speechcraft" */ '@/views/speechcraft/group/detail/Verification.vue');

const TagEndConfig = () => import(/* webpackChunkName: "speechcraft" */ '@/views/speechcraft/group/TagEndConfig.vue');

const detailRoutes = [
  {
    path: '',
    redirect: 'brief',
  },
  {
    path: 'brief',
    component: Brief,
  },
  {
    path: 'topic',
    component: Topic,
  },
  {
    path: 'pattern',
    component: PatternSetting,
  },
  {
    path: 'verification',
    component: Verification,
  },
];

const routes = [
  {
    path: 'group',
    component: Index,
    meta: { title: '话术检核管理' },
    children: [
      { path: '', redirect: 'list' },
      {
        path: 'list',
        component: List,
        meta: { title: '话术检核列表' },
      },
      {
        path: 'create',
        component: Detail,
        meta: { title: '添加话术检核' },
        children: [...detailRoutes],
      },
      {
        path: 'edit',
        component: Detail,
        meta: { title: '话术检核编辑' },
        children: [...detailRoutes],
      },
      {
        path: 'detail',
        component: Detail,
        meta: { title: '话术检核详情' },
        children: [...detailRoutes],
      },
      {
        path: 'tagEndConfig',
        component: TagEndConfig,
        meta: { title: '终端名称配置' },
      },
    ],
  },
];

export default routes;
