import Detail from './Detail';
import ConfigRouter from './Config';

const Index = () => import(/* webpackChunkName: "TrainingCenter" */  '@views/trainingCenter/offline/Index');

// 培训列表
const TrainingIndex = () => import(/* webpackChunkName: "TrainingCenter" */  '@views/trainingCenter/offline/training/Index');
const TrainingList = () => import(/* webpackChunkName: "TrainingCenter" */  '@views/trainingCenter/offline/training/List');

// 业务分类
const Category = () => import(/* webpackChunkName: "TrainingCenter" */  '@views/trainingCenter/offline/Category');

// 表单管理
const FormIndex = () => import(/* webpackChunkName: "TrainingCenter" */  '@views/trainingCenter/offline/form/Index');
const FormList = () => import(/* webpackChunkName: "TrainingCenter" */  '@views/trainingCenter/offline/form/List');
const FormDetail = () => import(/* webpackChunkName: "TrainingCenter" */  '@views/trainingCenter/offline/form/Detail');

const SignApproval = () => import(/* webpackChunkName: "TrainingCenter" */  '@views/trainingCenter/offline/signApproval');
const OrderApproval = () => import(/* webpackChunkName: "TrainingCenter" */  '@views/trainingCenter/offline/orderApproval');
const Config = () => import(/* webpackChunkName: "TrainingCenter" */ '@views/trainingCenter/offline/config/Index');


// 讲师配置
const Teacher = () => import(/* webpackChunkName: "TrainingCenter" */ '@views/trainingCenter/offline/teacher/Index');
const TeacherList = () => import(/* webpackChunkName: "TrainingCenter" */ '@views/trainingCenter/offline/teacher/List');

// 培训计划导出
const TrainPlanExport = () => import(/* webpackChunkName: "TrainingCenter" */ '@views/trainingCenter/offline/trainPlanExport');

// 培训
const trainingRoutes = [
  {
    path: 'training',
    component: TrainingIndex,
    children: [
      { path: '', redirect: 'list' },
      { path: 'list', component: TrainingList, meta: { title: '培训列表' } },
    ],
  },
];

// 表单
const formRoutes = [
  {
    path: 'form',
    component: FormIndex,
    meta: { title: '表单管理' },
    children: [
      { path: '', redirect: 'list' },
      { path: 'list', component: FormList, meta: { title: '表单列表' } },
      { path: 'create', component: FormDetail, meta: { title: '创建表单' } },
      { path: 'detail', component: FormDetail, meta: { title: '编辑表单' } },
    ],
  },
];

// 讲师
const teacherRoutes = [
  {
    path: 'teacher',
    component: Teacher,
    meta: { title: '讲师配置' },
    children: [
      { path: '', redirect: 'list' },
      { path: 'list', component: TeacherList, meta: { title: '讲师列表' } },
    ],
  },
];

const routes = [
  {
    path: 'offline',
    component: Index,
    meta: { title: '培训配置' },
    children: [
      { path: '', redirect: 'training' },
      ...trainingRoutes,
      ...formRoutes,
      ...teacherRoutes,
      { path: 'category', component: Category, meta: { title: '培训分类' } },
      { path: 'signApproval', component: SignApproval, meta: { title: '辅导报名审批' } },
      { path: 'orderApproval', component: OrderApproval, meta: { title: '辅导预约审批' } },
      { path: 'trainPlanExport', component: TrainPlanExport, meta: { title: '培训计划导出' } },
      { path: 'config', component: Config },
      ...Detail,
      ...ConfigRouter,
    ],
  },
];

export default routes;
