import Certificate from '@/router/view/certificate/Certificate';

const Index = () => import(/* webpackChunkName: "Certificate" */  '@views/certificate/Index');

const router = [
  {
    path: 'certificate',
    component: Index,
    meta: { title: '电子证书管理' },
    children: [
      { path: '', redirect: 'overview' },
      ...Certificate,
    ],
  },
];

export default router;
