import request from '@/utils/Request';

// 获取话术列表
export function getCategories() {
  return request({
    url: '/speechskill/admin/categorys',
    method: 'post',
  });
}

// 话术分类-保存1级分类
export function saveLevel1(data) {
  return request({
    url: '/speechskill/admin/categorys/save-one',
    method: 'post',
    data
  });
}

// 话术分类-保存2级分类
export function saveLevel2(data) {
  return request({
    url: '/speechskill/admin/categorys/save-two',
    method: 'post',
    data
  });
}

// 话术分类-删除
export function deleteCategory(data) {
  return request({
    url: '/speechskill/admin/categorys/delete',
    method: 'post',
    data
  });
}

