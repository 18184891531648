import request from '@/utils/Request';

// 话术组列表
export function getSkillGroupList(params) {
  return request({
    url: '/speechskill/admin/check',
    method: 'get',
    params,
  });
}

// 更改话术组状态-删除
export function deleteSkillGroup(data) {
  data = data || {};
  data.status = 'del';
  return request({
    url: '/speechskill/admin/check/set-status',
    method: 'post',
    data,
  });
}

// 更改话术组状态-上架
export function pullOnSkillGroup(data) {
  data = data || {};
  data.status = 'on';
  return request({
    url: '/speechskill/admin/check/set-status',
    method: 'post',
    data,
  });
}

// 更改话术组状态-下架
export function pullOffSkillGroup(data) {
  data = data || {};
  data.status = 'off';
  return request({
    url: '/speechskill/admin/check/set-status',
    method: 'post',
    data,
  });
}

// 话术检核 - 批量上架
export function batchPullOnSkillGroup(data) {
  data = data || {};
  data.status = 'on';
  return request({
    url: '/speechskill/admin/check/batch-set-status',
    method: 'post',
    data,
  });
}

// 话术检核 - 批量下架
export function batchPullOffSkillGroup(data) {
  data = data || {};
  data.status = 'off';
  return request({
    url: '/speechskill/admin/check/batch-set-status',
    method: 'post',
    data,
  });
}

// 话术组编辑-获取话术组信息
export function getSkillGroup(params) {
  return request({
    url: `/speechskill/admin/check/${params.id}`,
    method: 'get',
  });
}

// 话术组编辑-基本信息
export function saveSkillGroup(data) {
  return request({
    url: '/speechskill/admin/check/create',
    method: 'post',
    data,
  });
}

// 话术组编辑-模式设置
export function saveSkillGroupPattern(data) {
  return request({
    url: '/speechskill/admin/check/create-mode',
    method: 'post',
    data,
  });
}

// 话术组编辑-题目配置
export function saveSkillGroupTopic(data) {
  return request({
    url: '/speechskill/admin/check/create-skill',
    method: 'post',
    data,
  });
}

// 检核-配置校验
export function getSkillGroupCompleteStatus(params) {
  return request({
    url: `/speechskill/admin/check/check-mode/${params.id}`,
    method: 'get',
  });
}

// 编辑补考
export function saveMakeUp(data) {
  return request({
    url: '/speechskill/admin/check/makeup-exam',
    method: 'post',
    data,
  });
}
