import Vue from 'vue';

import ImageViewer from '../components/viewer/ImageViewer.vue';
import SelectBase from '../components/select/SelectBase.vue';
import SelectJob from '../components/select/SelectJob.vue';
import SelectJobGrouped from '../components/select/SelectJobGrouped.vue';
import SelectOrg from '../components/select/SelectOrg.vue';
import Loading from '../components/layout/Loading.vue';
import LoadingStatus from '../components/layout/LoadingStatus.vue';
import SelectShopType from '../components/select/SelectShopType';
import SelectBigArea from '../components/select/SelectBigArea';

Vue.component('app-image-viewer', ImageViewer);
Vue.component('app-select-base', SelectBase);
Vue.component('app-select-job', SelectJob);
Vue.component('app-select-job-grouped', SelectJobGrouped);
Vue.component('app-select-org', SelectOrg);
Vue.component('app-loading', Loading);
Vue.component('app-loading-status', LoadingStatus);
Vue.component('app-select-shop-type', SelectShopType);
Vue.component('app-select-big-area', SelectBigArea);
