import StatisticsParticipationBigAreaRouter from '@/router/view/statistics/participation/BigArea';
import StatisticsParticipationOrgRouter from '@/router/view/statistics/participation/Org';
import StatisticsParticipationExamRouter from '@/router/view/statistics/participation/Student';

const Index = () => import(/* webpackChunkName: "Statistics" */  '@views/statistics/participation/Index');

const Router = [
  {
    path: 'participation',
    component: Index,
    children: [
      { path: '', redirect: 'bigArea' },

      ...StatisticsParticipationBigAreaRouter,
      ...StatisticsParticipationOrgRouter,
      ...StatisticsParticipationExamRouter,
    ],
  },
];

export default Router;
