import { getStickerDetail } from '@/views/advertising/advertising/api/sticker';

const Script = {
  namespaced: true,
  state: {
    // 贴片
    stickerReadOnly: true,
    stickerId: 0,
    sticker: {},
    stickerList: [],
  },
  mutations: {
    // 贴片
    updateStickerReadOnly(state, readOnly) {
      state.stickerReadOnly = !!readOnly;
    },
    updateStickerId(state, id) {
      state.stickerId = id || 0;
    },
    updateSticker(state, detail) {
      state.sticker = detail || {};
    },
    updateStickerList(state, list) {
      state.stickerList = list;
    },
  },
  actions: {
    async getSticker({ commit }, id) {
      if (!id) {
        commit('updateStickerReadOnly', false);
        commit('updateStickerId', null);
        commit('updateSticker', {});
        return;
      }

      const data = await getStickerDetail({ id: id });
      if (!data || data.error_code) {
        return {};
      }
      const result = data?.data || {};
      commit('updateStickerReadOnly', result.status === 1);
      commit('updateStickerId', id);
      commit('updateSticker', result);
    },
  },
};

export default Script;
