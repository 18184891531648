import {
  getScriptStatus,
  getJobs,
  getOrgTree,
  getBigAreaIds,
  orgtreeByUser,
  getMenu,
  getPermissionList,
  getOfflineTaskList,
  getOnlineTaskList,
  getStoreType,
} from '@/api/Common';
import { getCategories } from '@/views/speechcraft/skill/api/category';

const Common = {
  namespaced: true,
  state: {
    scriptStatusList: [],
    scriptCategoryList: [],
    jobList: [],
    orgTree: [],
    bigAreaIds: [],
    orgStoreType: {},
    orgTreeDataCenter: [],
    offlineTaskList: [],
    onlineTaskList: [],
    menu: [],
    mergedMenu: [],
    studentMenu: [],
    permissionList : [],
  },
  mutations: {
    updateScriptStatusList(state, list) {
      state.scriptStatusList = list || [];
    },
    updateScriptCategoryList(state, list) {
      state.scriptCategoryList = list || [];
    },
    updateJobList(state, list) {
      state.jobList = list;
    },
    updateOrgTree(state, list) {
      state.orgTree = list;
    },
    updateBigAreaIds(state, list) {
      state.bigAreaIds = list;
    },
    updateOrgStoreType(state, list) {
      state.orgStoreType = list;
    },
    updateOrgTreeDataCenter(state, list) {
      state.orgTreeDataCenter = list;
    },
    updateOfflineTaskList(state, list) {
      state.offlineTaskList = list;
    },
    updateOnlineTaskList(state, list) {
      state.onlineTaskList = list;
    },
    updatePermissionList(state, list) {
      state.permissionList = list;
    },
    updateMenu(state, menu) {
      state.menu = menu || [];
    },
    updateMergedMenu(state, menu) {
      state.mergedMenu = menu || [];
    },
    updateStudentMenu(state, menu) {
      state.studentMenu = menu || [];
    },
  },
  actions: {
    async getScriptStatus({ commit }) {
      const data = await getScriptStatus();
      if (!data || data.error_code) {
        return [];
      }
      const result = data?.data || [];
      commit('updateScriptStatusList', result);
      return result;
    },

    async getScriptCategories({ commit }) {
      const data = await getCategories();
      if (!data || data.error_code) {
        return [];
      }
      const result = data?.data || [];
      commit('updateScriptCategoryList', result);
      return result;
    },

    async getJobs({ commit }) {
      const data = await getJobs();
      if (!data || data.error_code) {
        return [];
      }
      const result = data?.data || [];
      commit('updateJobList', result);
      return result;
    },

    async getOrgTree({ commit }) {
      const data = await getOrgTree();
      if (!data || data.error_code) {
        return [];
      }
      const result = data?.data || [];
      const list = [...result];
      let len = list.length;
      let level = 1;
      for (let i = 0; i < list.length; i += 1) {
        if (i < len) {
          list[i].level = level;
        }
        if (list[i]?.children?.length) {
          list.push(...list[i].children);
        }

        if (i >= len - 1) {
          len = list.length;
          level += 1;
        }
      }
      commit('updateOrgTree', result);
      return result;
    },

    async getBigAreaIds({ commit }) {
      const data = await getBigAreaIds();
      if (!data || data.error_code) {
        return [];
      }
      const result = data?.data || [];
      commit('updateBigAreaIds', result);
      return result;
    },

    async getStoreType({ commit }) {
      const data = await getStoreType();
      if (!data || data.error_code) {
        return [];
      }
      commit('updateOrgStoreType', {
        operation_state: data?.data?.operation_state?.map(s => {
          return { value: s?.id.toString(), label: s?.title };
        }),
        store_age: data?.data?.store_age?.map(s => {
          return { value: s?.id.toString(), label: s?.title };
        }),
        store_state: data?.data?.store_state?.map(s => {
          return { value: s?.id.toString(), label: s?.title };
        }),
        store_type: data?.data?.store_type?.map(s => {
          return { value: s?.id.toString(), label: s?.title };
        }),
      });
      return data.data;
    },
    async orgtreeByUser({ commit }) {
      const data = await orgtreeByUser({
        select_org_range: 'five_big_area_tree',
      });
      if (!data || data.error_code) {
        return [];
      }
      const result = data?.data || [];
      const list = [...result];
      let len = list.length;
      let level = 1;
      for (let i = 0; i < list.length; i += 1) {
        if (i < len) {
          list[i].level = level;
        }
        if (list[i]?.children?.length) {
          list.push(...list[i].children);
        }

        if (i >= len - 1) {
          len = list.length;
          level += 1;
        }
      }
      commit('updateOrgTreeDataCenter', result);
      return result;
    },

    async getOfflineTaskList({ commit }, params) {
      const data = await getOfflineTaskList(params);
      if (!data || data.error_code) {
        return [];
      }
      const result = data?.data || [];
      commit('updateOfflineTaskList', result);
      return result;
    },

    async getOnlineTaskList({ commit }, params) {
      const data = await getOnlineTaskList(params);
      if (!data || data.error_code) {
        return [];
      }
      const result = data?.data || [];
      commit('updateOnlineTaskList', result);
      return result;
    },

    async getMenu({ commit }) {
      const data = await getMenu();
      if (!data || data.error_code) {
        return [];
      }
      const menu = data?.data || [];
      const result = [];
      for (let key in menu) {
        if (Object.hasOwnProperty.call(menu, key)) {
          if (menu[key].jump_url === '/index/speechSkill/') {
            menu[key].jump_url = '/index/speechcraft/';
          }
          if (menu[key].jump_url === '/index/course/course-package/') {
            menu[key].jump_url = '/index/course/';
          }
          result.push(menu[key]);
        }
      }
      commit('updateMenu', result);
      return result;
    },

    async getPermissionList({ commit }, params) {
      const data = await getPermissionList(params);
      if (!data || data.error_code) {
        return [];
      }
      const result = data?.data || [];
      commit('updatePermissionList', result);
      return result;
    },

  },
};

export default Common;
