<template>
  <div class="app-block-loading">
    <a-spin :size="size" :tip="tip" class="app-spin" :style="{'zoom': zoom}"></a-spin>
  </div>
</template>

<script>
export default {
  name: 'BlockLoading',
  props: {
    size: {type: String, default: 'large', validate: (size) => ['small', 'default', 'large'].includes(size)},
    tip: {type: String, default: '加载中...'},
    zoom: {type: Number, default: 1},
  },
}
</script>

<style scoped lang="scss">
.app-block-loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: rgba(214, 214, 214, 0.6);
}
</style>
